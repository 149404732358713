const newPasswordDesc =
  "We've sent you a 6 digit confirmation email. Please enter the code in the box below in order to verify your email.";
const otpDesc =
  'Please enter the One Time Code we sent you on your registered email address';
const noTraitsMsg = 'Artist dont have any genre please select another artist';
const referralDesc =
  "Give your friends a head start with $5 in credit! \n It's easy: just share your custom link with them and we'll give both of you $5 when they purchase their first song from us.";
const youGet =
  'When the person you referred completes a purchase of his first song, you will get $5 credit to use in Songoven';
const theyGet =
  'When an account is created using your referral link, they will immediately get $5 credit to purchase a song';
const shareLink =
  'Share your unique referral link with your family, friends and the rest of the world';
const artistsDesc =
  'We work with some of the top singers, songwriters, and musicians out there. Take a look at our artists and listen to the original songs they have created to discover the great talent we team up with.';
const songRecipientDesc = 'Share your thoughts...';
const reviewDesc =
  'Please make sure that the information you provided is accurate, that the dates, names and places are clear so that the artist understands in the clearest way the information he needs to use to produce the song.';
const reviewConfirmDesc =
  'I confirm that I will not be able to make changes after the order has been submitted.';
const congratulations =
  'We are gathering all the ingredients you submitted so we can insert your song in to the oven';
const congratulationsinstant =
  'Your custom song is ready! Click the link below to enjoy it now';
const rushFee =
  'Our standard completion time for order is 8 days. If you need your order earlier, you can expedite it with a rush fee of $25 per day.';
const orderCompleteDesc =
  "So you've ordered the song and the anticipation is killing you! It's time to make that moment even more special. How about recording a video when the song first reaches your ears, so you can treasure your reaction forever?";
const orderCompleteRecord =
  'All you have to do is hit the Record Video button, you will hear the song and the moment will be cherished to share it with other.';
const recordingTxt =
  'After the video recording, you will be directed to your account where you can listen to the';
const recordingApprove = 'Please approve the use of camera for recording';
const pronounceCorrect =
  'Let’s make sure the name is pronounced correctly in the song';
const dashboardSection1 =
  'We have the best recipe and the right ingredients to bake one-of- a-kind song from your stories and memories.';
const occasionsDesc =
  "There's something special about the power of songs to bring people together and make special moments more meaningful.";
const shareChecklabel = 'Allow visitors to see your recorded video reaction';
const essentialDetail =
  'Let your artist know what out of all the details you provided are the';
const essentialSubDetail =
  'Select up to 3 topics that must be included in the song';
const audioGreetingDesc =
  'Add up to 30 seconds of an audio recording of your voice as a personal greeting.';
const audioGreetingShipping =
  'If ordered after receiving the song, it will be delivered within 4-7 days.';
const audioGreetingRecord =
  'The greeting will be integrated at the end of the song while the melody is playing in the background.';
const additionalVerseAck =
  'I am aware that all the information I have provided is accurate and cannot be changed after confirmation.';
const additionalVerseDesc2 =
  'Please provide any essential information you would included in the additional verse.';
const additionalVerseShipping1 = 'Delivered as part of the custom song.';
const additionalVerseShipping2 =
  'If ordered after receiving the song, it will be delivered within 4-7 days. Please bear in mind that availability of your artist may affect the possibility of adding an additional verse to your song.';
const additionalVerseDesc1 =
  'Incorporating an additional verse into your original song provides a richer opportunity for the artist to weave more of your story into the lyrics, enhancing the overall composition.';
const tipSent = 'Your tip will be sent directly to the artist.';
const tipDesc =
  'Express an additional "thank you" to your artist for creating a memorable and impressive moment.';
const lyricsSheetDesc =
  'Get a unique print of your song on a beautiful, custom designed sheet.';
const lyricsSheetShipping =
  "Delivered digitally as a PDF within 3-6 days of the song's completion.";
const playbackDesc =
  'Get the instrumental version of your song so you can record your own voice singing or sing karaoke with it.';
const playbackShipping =
  'The playback will be delivered to you as a digital MP3 file once the song is completed and no revisions are required.';
const lyricsSheetShippingTopping =
  'Delivered digitally as a PDF within 24 hours.';
const spotifyTopping =
  'Delivered digitally within 3-6 days from song completion.';
  const foolingCustomer = "Pay with a smile, for a song that's worthwhile!";
const encrypedTxt = 'This is a secure 128-bit SSL encrypted payment';
const bobDrops = `"One good thing about music, when it hits you, you feel no pain." - Bob Marley`;
const privacyPolicyAggreement = `By checking this box, I agree to the (Terms of Use)[https://songoven.com/terms-of-use] and (Privacy Policy)[https://songoven.com/privacy-policy/].`;
const reminderDesc = `Set a date with us and get a reminder to put together a unique song for your loved one.`;
const secondaryDetails = `Tap on an artist to listen to a sample of their vocal skills and unique style`;
const deleteSongWarning = `You will lose all the song details entered in the previous steps`;
const deleteExtraChargeWarning = `The extra charge request created by admin will be removed and admin have to create that request again`;
const resetPasswordDetail = `Submit your Email and we will send you instructions on how to reset your password.`;
const deliveryDateInfo = `Your custom song will be delivered by the end of the specified date below`;
const deliverOptionsInfo = `Your custom song will be delivered after payment`;
const busyArtist =
  'Our artists value both their personal growth and commitment to fans. Occasionally, they may take time off to focus on creating their own productions, or they might be on the road, touring and performing. This ensures they bring their best selves and art to you. We appreciate your understanding and patience.';
const selectSong = 'Delivered as part of the custom song.';
const memories = 'Making memories and spreading cheer with every song...';
const bestRecipe =
  "Transform your special moments into a one-of-a kind song with our new revolutionary service. Just fill out the details for your Custom Song, and in just a few minutes, you'll have your song ready to enjoy. All this for only $23.99 (USD)";

export {
    youGet,
    tipDesc,
    tipSent,
    rushFee,
    otpDesc,
    theyGet,
    memories,
    bobDrops,
    shareLink,
    bestRecipe,
    selectSong,
    reviewDesc,
    busyArtist,
    encrypedTxt,
    artistsDesc,
    noTraitsMsg,
    recordingTxt,
    referralDesc,
    playbackDesc,
    reminderDesc,
    occasionsDesc,
    shareChecklabel,
    congratulations,
    congratulationsinstant,
    newPasswordDesc,
    essentialDetail,
    lyricsSheetDesc,
    foolingCustomer,
    deliveryDateInfo,
    playbackShipping,
    secondaryDetails,
    pronounceCorrect,
    recordingApprove,
    songRecipientDesc,
    reviewConfirmDesc,
    orderCompleteDesc,
    deleteSongWarning,
    audioGreetingDesc,
    dashboardSection1,
    essentialSubDetail,
    additionalVerseAck,
    deliverOptionsInfo,
    resetPasswordDetail,
    orderCompleteRecord,
    audioGreetingRecord,
    lyricsSheetShipping,
    additionalVerseDesc1,
    additionalVerseDesc2,
    audioGreetingShipping,
    privacyPolicyAggreement,
    deleteExtraChargeWarning,
    additionalVerseShipping1,
    additionalVerseShipping2,
    lyricsSheetShippingTopping,
    spotifyTopping,
}