import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { create_visitor } from '~auth/utils';
import { baseUrl } from '~config/base-url';
import store from '~store/index';

const baseQuery =
  (baseQueryArgs = {}) =>
  async (fetchArgs: any, api: any, extraOptions: any) => {
    const doFetch = () =>
      fetchBaseQuery({
        ...baseQueryArgs,
        baseUrl,
        prepareHeaders: (headers, { getState }: any) => {
          const token = getState().auth.user?.access_token;

          if (token) headers.set('authorization', `Bearer ${token}`);

          return headers;
        },
      })(fetchArgs, api, extraOptions);

      let result = doFetch();

    const preCheckResult = await result;
    // console.log("fetchBaseQuery", preCheckResult);

      if (preCheckResult?.error?.status === 401) {
        console.log('error', preCheckResult?.error?.data as any);
        const state = (store as any)?.getState?.();
        const userIsTempUser = state?.auth?.user?.userDto?.email?.match?.(/^[a-f\d]{24}@songoven\.com$/i);
        const noUser = !state?.auth?.user;
        if ( (!userIsTempUser) || noUser) {
          const response = await create_visitor();
          console.log('create_visitor2', response);
          result = doFetch();
          const preCheckResult2 = await result;
          console.log('fetchBaseQuery2', preCheckResult2);
          return Promise.resolve(preCheckResult2);
        }
        else
        {
          return Promise.resolve(preCheckResult);
        }
      } else return Promise.resolve(preCheckResult);
    };

export const api = createApi({
  tagTypes: [
    'Topic',
    'Order',
    'AISongs',
    'Artist',
    'Balance',
    'Refferal',
    'Occasion',
    'OrderDetail',
    'ExtraCharge',
    'Relationship',
    'PreviousOrders',
    'OccasionDetails',
    'CustomOrderSummaries',
    'PreviousOrderSummaries',
    'SongComments',
  ],
  baseQuery: baseQuery(),
  endpoints: () => ({}),
});
