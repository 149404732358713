const orderSliceState = {
  step1FormData: {
    _id: null,
    started: false,

    gifterName: '',
    gifterEmail: '',

    recipientName: '',
    songLanguage: 'English',
    recipientPronounce: '',
    recipientNameFile: '',
    mentionRecipientInSong: true,

    recipientRelationship: null,

    occasion: null,
    mentionOccasionInSong: true,

    existsInDB: false,
    isAiSong: true,
  },
  step2FormData: {
    _id: null,
    artistIds: [],
    musicTraits: [],
    AIGender: '',

    existsInDB: false,
  },
  previewSong: {
    hadPreviewSong: false,
    disablePreviewSong: false,
    justWasAtPreviewSong: false,
    earlyCheckout: false,
  },
  step3FormData: {
    orderId: null,
    aboutSong: '',
    orderTopicDtos: [],

    existsInDB: false,
  },
  step4FormData: {
    orderId: null,
    deliveryDate: null,
    // billingAddressDto: {
    //     fullName: "",
    //     phoneNumber: "",
    //     billingAddress1: "",
    //     billingAddress2: "",
    //     city: "",
    //     state: "",
    //     zipCode: "",
    // },
    paymentMethodDto: {
      cardNumber: '',
      cardHolderName: '',
      expirationDate: '',
      cvv: '',
    },
    couponName: '',

    existsInDB: false,
    instantSongZohoSent: false,
    instantSong_hash: 0,

    selectedPaymentMethod: 'paypal',
  },
  currentArtist: {
    firstName: '',
    lastName: '',
    stageName: '',
    pfpPath: '',
  },
  secondaryArtist: {
    firstName: '',
    lastName: '',
    stageName: '',
    pfpPath: '',
  },
  gender: null,

  artistSelected: false,
  needHelp: false,

  addonInCustomSong: [],

  previousAddons: false,

  noCreditOffset: false,

  paypalReady: false,

  paypalError: false as boolean | string,
  lastTotalAmount: 0.0,
  useCalculateOrderSumariesVersion: 3,

  lyricsEditor: {
    editLyricsOpen: false,
    editLyricsChanged: false,
    editLyricsGenerate: 'ended',
    editLyricsEdit: 'closed',
    editSongName: 'Unnamed Song',
    editSongLyrics: '',
    editSongLyricsHistory: [] as Array<string>,
    firstTimeAutoPlay: true,
  },

  previousOrderDetails: {
    orderId: null,
    lastKnownTotal: 0.0,
    numberOfSongs: 1,
    productName: '',
    couponName: '',
    goto_account: false,
  },

  orderStatus: 0 as number | undefined,

  isOrderDetailsEdit: false,

  isOrderDetailsScreenEdit: false,

  askToRegister: 0 as number | false, // values are 3 10 or falce

  songAddonToppingId: 0,

  selectedSongVersion: '',

  AddonsPageFromSong: '',
  AddonsPageToSong: '',
};
const authSliceState = {
  user: null,
  subscriberUserInfo: null,
  savedPath: null,
  resetPasswordProps: null,
  draftLoaded: false,
};

export { authSliceState, orderSliceState };
