import { getId } from '~helper/index';

export const _faqs = [
  {
    id: getId(),
    summary: 'How much does a custom song cost?',
    details:
      'Our custom song, created using advanced music technology and delivered within 24 hours, costs only $23.99 USD. For a fully custom song crafted by an artist, the price is $199 USD, with delivery within 4 days of your order.',
  },
  {
    id: getId(),
    summary:
      'Is it possible to receive a refund if I am not satisfied with the song?',
    details:
      'Due to the custom nature of our songs, we are unable to offer refunds. Each song is uniquely tailored to your preferences and requirements, making it impossible to reuse or resell. This level of personalization ensures your song is a one-of-a-kind creation meant just for you. However, we do offer up to two revisions to make sure your song meets your expectations.',
  },
  {
    id: getId(),
    summary: 'How will I receive my song?',
    details:
      'Once the production process is complete, we will send you an email notification that includes a link to your song page, where you can listen to it and share it with your loved ones.',
  },

  {
    id: getId(),
    summary: 'Can you share with me the custom song production process?',
    details:
      "Our Custom Song Production Process: After sharing your story through our order form, you can preview and adjust lyrics until they’re just right. Once approved, we’ll create four unique musical versions within 24 hours, allowing you to choose your favorite. Whether generated with advanced music technology or crafted by an artist, your song will be a personalized, one-of-a-kind musical experience.",
  },
];
